import { graphql, PageProps } from 'gatsby';
import React, { FC } from 'react';

import {
  Hero,
  HeroData,
  RoomContentSection,
  RoomsContentData,
} from '~src/components/sections';
import type { SeoData } from '~src/layouts/components/seo';
import Layout from '~src/layouts/page-layout';
import { StrapiRoomPage } from '~src/types/generated/graphql-gatsby';

type Data = {
  strapiRoomPage: StrapiRoomPage;
};

const RoomDetails: FC<PageProps<Data>> = ({
  data: { strapiRoomPage },
  location,
}) => {
  const seoData = strapiRoomPage.seo as SeoData;
  const heroData = strapiRoomPage.hero as HeroData;
  const contentData = {
    content: strapiRoomPage.content,
    amenities: strapiRoomPage.amenities,
    images: strapiRoomPage.gallery,
    pathname: location.pathname,
  } as RoomsContentData;

  return (
    <Layout pathname={location.pathname} seoData={seoData}>
      <Hero small data={heroData} />
      <RoomContentSection data={contentData} />
    </Layout>
  );
};

export default RoomDetails;

export const roomDetailsQuery = graphql`
  query RoomDetails($locale: String!, $pageID: String!) {
    strapiRoomPage(locale: { eq: $locale }, pageID: { eq: $pageID }) {
      slug
      seo {
        title
        description
        featuredImage {
          alt
          image {
            localFile {
              ...FeaturedImg
            }
          }
        }
      }
      hero {
        title
        subtitle
        coverImage {
          alt
          desktop {
            localFile {
              ...DesktopCoverImg
            }
          }
          phone {
            localFile {
              ...PhoneCoverImg
            }
          }
        }
      }
      content {
        title
        text
        bookLabel
      }
      amenities {
        id
        title
      }
      gallery {
        id
        alt
        image {
          previewImg: localFile {
            ...GalleryPreviewImg
          }
          thumbImg: localFile {
            ...ThumbImg
          }
          slideImg: localFile {
            ...SlideImg
          }
        }
      }
    }
  }
`;
